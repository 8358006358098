$turquoise: #edf4fa;
$active_nav_item: #2f353a;
$read_background_color: #f0f3f5;
$read_color: #5c6873;

body {
	background-color: $turquoise;
	font-size: 1rem;
}

.nav-tabs {
	.nav-item {
		.nav-link {
			color: $black !important;
		}
	}
}

.color-black {
    color: #000000;
}

.half-offset {
	margin-left: 4.166666667%;
}

.app-footer {
	flex: none;

	div {
		font-size: 0.75rem;
	}
}

.rdtTimeToggle {
	background-color: $babyBlue;
}

#nprogress {
	.bar {
		background: $babyBlue !important;

		 height: 5px !important;
	}

	.peg {
		display: none !important;
	}

	.spinner {
		display: none !important;
	}
}

.list-group-item {
	div {
		padding-left: 5px;
		padding-right: 5px;
	}

	&.list-group-item-action.not-read {

	}

	&.list-group-item-action.read {
	    color: $read_color;
	    text-decoration: none;
	    background-color: $read_background_color;
	}
}

.listPagination {
	margin-top: 20px;
}

.btn-group {
	.btn-block + .btn-block {
		margin-top: 0;
	}
}

IMG.img-loading {
	opacity: 0.5;
}

.colour-black,
button.clear-filter-button,
button.clear-search-bar-button {
	color: $black;
}

.star-rating {
	.fa-star {
		color: $babyBlue;
	}
}

.sort-dropdown-menu {
	min-width: 100%;
	text-align: center;
}

.filter-menu {
	label {
		color: $read_color;
	}
}

.notification-dropdown-header {
	background: white;
}

.notification-dropdown-item {
	white-space: normal;

	&.not-read {
		
	}

	&.read {
		background: $read_background_color;
		color: $read_color !important;
	}
}

@media only screen and (max-width: 480px) {
	.app-header .nav-item {
		min-width: 40px;

		.nav-link > .img-avatar, .app-header .nav-item .avatar.nav-link > img {
			margin: 0 20px;
		}
	}

	.main .container-fluid {
		padding: 0 10px;
	}

	.breadcrumb {
		margin-bottom: 1rem;
	}
}

@media (min-width: 576px) {
	.card-group.viewing-card-group {
		.card {
			border-radius: 0;
		}

		&:first-of-type {
			.card:first-child {
				border-top-left-radius: 0.25rem;
			}
			.card:last-child {
				border-top-right-radius: 0.25rem;
			}
		}
		&:last-of-type {
			.card:first-child {
				border-bottom-left-radius: 0.25rem;
			}
			.card:last-child {
				border-bottom-right-radius: 0.25rem;
			}
		}
	}
}
.one-line-desc-services
{
	list-style: none;
	padding-left: 0;
	display: inline-block;
	margin-right: -0.25rem;
	margin-left: -0.25rem;
}

.one-line-desc-services li {
	display: inline-block;
}

.price-table-length-dropdown {
	max-height: 80vh;
	overflow-y: scroll;
	white-space: pre-wrap;
	width: 600px;
	button {
		white-space: pre-wrap;
	}
	@media (max-width: 576px) {
		max-width: 400px;
	}
}

.form-group-margin-left {
	margin-left: 1.3rem;
}


.map-container {
	height: 400px !important;
	width: 100%;
}