// Import Viewber variables.
@import 'variables';

// Import Font Awesome Icons Set
@import '~font-awesome/css/font-awesome.min.css';

// Import CoreUI required styles
@import "~@coreui/coreui/scss/variables";

// Import Bootstrap required styles.
@import "~@coreui/coreui/scss/bootstrap/functions";
@import "~@coreui/coreui/scss/bootstrap/variables";
@import "~@coreui/coreui/scss/bootstrap/mixins";

// Modify theme maps.
$theme-colors: map-remove($theme-colors, 'info', 'dark');

// Import Bootstrap optional styles.
@import "~@coreui/coreui/scss/bootstrap/root";
@import "~@coreui/coreui/scss/bootstrap/reboot";
@import "~@coreui/coreui/scss/bootstrap/type";
@import "~@coreui/coreui/scss/bootstrap/images";
@import "~@coreui/coreui/scss/bootstrap/code";
@import "~@coreui/coreui/scss/bootstrap/grid";
@import "~@coreui/coreui/scss/bootstrap/tables";
@import "~@coreui/coreui/scss/bootstrap/forms";
@import "~@coreui/coreui/scss/bootstrap/buttons";
@import "~@coreui/coreui/scss/bootstrap/transitions";
@import "~@coreui/coreui/scss/bootstrap/dropdown";
@import "~@coreui/coreui/scss/bootstrap/button-group";
@import "~@coreui/coreui/scss/bootstrap/input-group";
@import "~@coreui/coreui/scss/bootstrap/custom-forms";
@import "~@coreui/coreui/scss/bootstrap/nav";
@import "~@coreui/coreui/scss/bootstrap/navbar";
@import "~@coreui/coreui/scss/bootstrap/card";
@import "~@coreui/coreui/scss/bootstrap/breadcrumb";
@import "~@coreui/coreui/scss/bootstrap/pagination";
@import "~@coreui/coreui/scss/bootstrap/badge";
@import "~@coreui/coreui/scss/bootstrap/jumbotron";
@import "~@coreui/coreui/scss/bootstrap/alert";
@import "~@coreui/coreui/scss/bootstrap/progress";
@import "~@coreui/coreui/scss/bootstrap/media";
@import "~@coreui/coreui/scss/bootstrap/list-group";
@import "~@coreui/coreui/scss/bootstrap/close";
@import "~@coreui/coreui/scss/bootstrap/toasts";
@import "~@coreui/coreui/scss/bootstrap/modal";
@import "~@coreui/coreui/scss/bootstrap/tooltip";
@import "~@coreui/coreui/scss/bootstrap/popover";
@import "~@coreui/coreui/scss/bootstrap/carousel";
@import "~@coreui/coreui/scss/bootstrap/spinners";
@import "~@coreui/coreui/scss/bootstrap/utilities";
@import "~@coreui/coreui/scss/bootstrap/print";

// Import CoreUI optional styles.
@import "~@coreui/coreui/scss/mixins";
@import "~@coreui/coreui/scss/animate";
@import "~@coreui/coreui/scss/vendors";
@import "~@coreui/coreui/scss/aside";
@import "~@coreui/coreui/scss/avatars";
@import "~@coreui/coreui/scss/badge";
@import "~@coreui/coreui/scss/breadcrumb-menu";
@import "~@coreui/coreui/scss/breadcrumb";
@import "~@coreui/coreui/scss/button-group";
@import "~@coreui/coreui/scss/buttons";
@import "~@coreui/coreui/scss/callout";
@import "~@coreui/coreui/scss/card";
@import "~@coreui/coreui/scss/charts";
@import "~@coreui/coreui/scss/dropdown";
@import "~@coreui/coreui/scss/footer";
@import "~@coreui/coreui/scss/grid";
@import "~@coreui/coreui/scss/header";
@import "~@coreui/coreui/scss/input-group";
@import "~@coreui/coreui/scss/images";
@import "~@coreui/coreui/scss/list-group";
@import "~@coreui/coreui/scss/modal";
@import "~@coreui/coreui/scss/nav";
@import "~@coreui/coreui/scss/progress";
@import "~@coreui/coreui/scss/progress-group";
@import "~@coreui/coreui/scss/sidebar";
@import "~@coreui/coreui/scss/switches";
@import "~@coreui/coreui/scss/tables";
@import "~@coreui/coreui/scss/widgets";
@import "~@coreui/coreui/scss/layout";
@import "~@coreui/coreui/scss/others";
@import "~@coreui/coreui/scss/utilities";
@import "~@coreui/coreui/scss/rtl";
@import "~@coreui/coreui/scss/ie-custom-properties";
@import "~@coreui/coreui/scss/fixes";

// Temp fix for reactstrap
@import '~@coreui/coreui/scss/_dropdown-menu-right';

// Import React Toastify styling
@import '~react-toastify/dist/ReactToastify.min.css';

// Import React DateTime styling
@import 'react-datetime';

// Import Nprogress styling
@import '~nprogress/nprogress.css';

// Custom Viewber Styles.
@import "custom";
@import "app-header";
@import "buttons";
@import "calendar";
@import "card-header-actions";
@import "dropzone";
@import "forms";
@import "icons";
@import "ie-fix";
@import "notifications";
@import "page-title";
@import "pagination";
@import "placeholder-shimmer";
@import "sidebar";
@import "utilities";
@import "calendar";
